import React from 'react';
import Helmet from 'react-helmet';
import favicons from '../data/favicons';

const faviconsFormatted = favicons.map((favicon) => {
  return {
    rel: favicon.rel,
    type: favicon.type || '',
    sizes: favicon.sizes,
    href: `/favicons/${favicon.iconName}`
  };
});

export default () => {
  return (
    <Helmet
      title={'DG Visual Media'}
      meta={[
        {
          name: `description`,
          content: 'Manchester Based Branding &amp; Signage Company'
        },
        {
          property: `og:title`,
          content: 'DG Visual Media'
        },
        {
          property: `og:description`,
          content: 'Manchester Based Branding &amp; Signage Company'
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: 'og:url',
          content: 'https://www.dgvisualmedia.com'
        },
        {
          name: 'og:image',
          content: `https://www.dgvisualmedia.com/og/og-image.png`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:card`,
          content: `https://www.dgvisualmedia.com`
        },
        {
          name: `twitter:creator`,
          content: '@DG_VisualMedia'
        },
        {
          name: `twitter:title`,
          content: 'DG Visual Media'
        },
        {
          name: `twitter:description`,
          content: 'Manchester Based Branding &amp; Signage Company'
        },
        {
          name: 'twitter:image',
          content: `https://www.dgvisualmedia.com/og/og-image.png`
        },

        {
          name: `viewport`,
          content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
        },
        {
          name: 'msapplication-TileColor',
          content: '#ffffff'
        },
        {
          name: 'msapplication-TileImage',
          content: '/favicons/ms-icon-144x144.png'
        },
        {
          name: 'theme-color',
          content: '#ffffff'
        }
      ]}
      link={faviconsFormatted}
    />
  );
};
