module.exports = [
  {
    rel: 'apple-touch-icon',
    sizes: '57x57',
    iconName: 'apple-icon-57x57.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '60x60',
    iconName: 'apple-icon-60x60.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '72x72',
    iconName: 'apple-icon-72x72.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '76x76',
    iconName: 'apple-icon-76x76.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '114x114',
    iconName: 'apple-icon-114x114.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '120x120',
    iconName: 'apple-icon-120x120.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '144x144',
    iconName: 'apple-icon-144x144.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '152x152',
    iconName: 'apple-icon-152x152.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    iconName: 'apple-icon-180x180.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '192x192',
    iconName: 'android-icon-192x192.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    iconName: 'favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '96x96',
    iconName: 'favicon-96x96.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '70x70',
    iconName: 'ms-icon-70x70.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '144x144',
    iconName: 'ms-icon-144x144.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '150x150',
    iconName: 'ms-icon-150x150.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '310x310',
    iconName: 'ms-icon-310x310.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    iconName: 'favicon-16x16.png',
  },
]
