import React from 'react'
import { Link } from 'gatsby'

export default () => (
  <React.Fragment>
    <div className="container-fluid ">
      <div className="row">
        <div className="col-12">
          <hr className="blue" />
          <hr className="yellow" />
        </div>
      </div>
    </div>
    <div className="container-fluid contact-bg ">
      <div className="row">
        <div className="col-12">
          <div className="container contact">
            <div className="row">
              <div id="contact" className="col-12 section text-center">
                <h1 className="section--title">Contact</h1>
              </div>
              <div className="col-12">
                <ul className="contact-items">
                  <li className="phone">07722508634</li>
                  <li className="email">
                    <a href="mailto:info@dgvisualmedia.com">
                      info@dgvisualmedia.com
                    </a>
                  </li>
                  <li className="facebook">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/DG-Visual-Media-360316414159277"
                    />
                  </li>
                  <li className="twitter">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/dg_visual_media/"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
)
