import React from 'react'
import Navbar from './Navbar'
import SEO from './SEO'
import jquery from 'jquery'
if (typeof window !== `undefined`) {
  window.$ = window.jQuery = jquery
}

import '../css/main.css'

class Template extends React.Component {
  render() {
    const { location, children } = this.props

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <React.Fragment>
        <SEO />
        <Navbar />
        {children}
      </React.Fragment>
    )
  }
}

export default Template
